import React from 'react';
import T from 'prop-types';
import styled, { css } from 'styled-components';
import { ButtonGroup, Button } from '@devseed-ui/button';
import { themeVal, glsp } from '@devseed-ui/theme-provider';
import {
  CollecticonBrandDevelopmentSeed,
  CollecticonPlus
} from '@devseed-ui/collecticons';

import Constrainer from '../../styles/constrainer';
import SandboxLayout from './_layout';

// How to add a new sandbox page:
// Follow the instructions on ./_layout.js

const Fold = styled.div`
  margin-bottom: ${glsp(2)};
`;

const Ul = styled.ul`
  list-style: none !important;
  margin: 0 !important;
  padding: 0 !important;

  > li {
    margin-bottom: 1rem;
  }

  > li:last-child {
    margin-bottom: 0;
  }
`;

const VariationsWrapper = styled.div`
  display: grid;
  grid-gap: 2rem;
  grid-template-columns: repeat(4, 1fr);

  ${({ isDark }) =>
    isDark &&
    css`
      padding: 2rem;
      background: ${themeVal('color.base')};
    `}
`;

// Below the different button variations and sizes to render all buttons.

const variations = [
  'base-text',
  'base-fill',
  'base-outline',
  'primary-text',
  'primary-fill',
  'primary-outline',
  'danger-text',
  'danger-fill',
  'danger-outline',
  'success-text',
  'success-fill',
  'success-outline'
];
const lightVariations = ['achromic-text', 'achromic-outline'];
const sizes = ['small', 'large', 'xlarge'];

const SandboxButtons = ({ location }) => {
  return (
    <SandboxLayout location={location} title='Sandbox buttons'>
      <Constrainer>
        <Fold>
          <h2>Button Group</h2>
          <div>
            <ButtonGroup orientation='horizontal'>
              <Button variation='base-outline'>First</Button>
              <Button variation='base-outline'>Second</Button>
              <Button variation='base-outline'>Third</Button>
              <Button variation='base-outline'>Last</Button>
            </ButtonGroup>
          </div>
          <div>
            <ButtonGroup orientation='vertical'>
              <Button variation='base-outline'>First</Button>
              <Button variation='base-outline'>Second</Button>
              <Button variation='base-outline'>Third</Button>
              <Button variation='base-outline'>Last</Button>
            </ButtonGroup>
          </div>
          <div>
            <ButtonGroup orientation='horizontal'>
              <Button variation='base-outline'>
                <CollecticonPlus /> First
              </Button>
              <Button variation='base-outline'>
                <CollecticonPlus /> Second
              </Button>
              <Button variation='base-outline'>
                <CollecticonPlus /> Third
              </Button>
            </ButtonGroup>
          </div>
        </Fold>

        <Fold>
          <h2>Disabled Buttons</h2>
          <p>
            Any button can be visually disabled using the{' '}
            <code>visuallyDisabled</code> prop.
            <br />
            This results in a button that looks disabled with a not-allowed
            cursor. Additionally, the pointer events are still active, allowing
            for events to be attached to it. <br />
            Both buttons have a click event attached:
          </p>

          <Button
            variation='base-outline'
            onClick={() => alert('I still work')}
            visuallyDisabled
          >
            Visually disabled
          </Button>

          <Button
            variation='base-outline'
            onClick={() => alert('Never triggered')}
            disabled
          >
            Disabled
          </Button>

          <p>
            The buttons are described by <code>[size] + [variation]</code>
          </p>
        </Fold>

        <Fold>
          <h2>Button and icons</h2>
          <p>
            Visit{' '}
            <a
              href='https://collecticons.io'
              title='Visit the Collecticons library page'
              target='_blank'
              rel='noreferrer noopener'
            >
              Collecticons.io
            </a>{' '}
            for the full list of icons
          </p>
          <Button variation='base-outline'>
            <CollecticonBrandDevelopmentSeed /> Icon before
          </Button>
          <Button variation='base-outline'>
            Icon after <CollecticonBrandDevelopmentSeed />
          </Button>
        </Fold>

        <Fold>
          <h2>Button as other elements</h2>
          <p>
            The buttons can be used as another element using the{' '}
            <code>forwardedAs</code> prop.
            <br />
            This is a styled component property and must be used in alternative
            to <code>as</code>, so the button doesn&apos;t loose its component
            structure.
          </p>
          <Button forwardedAs='a' variation='base-outline'>
            This is an &lt;a&gt;
          </Button>
        </Fold>

        <Fold>
          <h2>Button variations</h2>

          <VariationsWrapper>
            {variations.map((variation) => (
              <Ul key={variation}>
                {sizes.map((size) => (
                  <li key={size}>
                    <Button variation={variation} size={size}>
                      {size} - {variation}
                    </Button>
                  </li>
                ))}
              </Ul>
            ))}
          </VariationsWrapper>

          <VariationsWrapper isDark>
            {lightVariations.map((variation) => (
              <Ul key={variation}>
                {sizes.map((size) => (
                  <li key={size}>
                    <Button variation={variation} size={size}>
                      {size} + {variation}
                    </Button>
                  </li>
                ))}
              </Ul>
            ))}
          </VariationsWrapper>
        </Fold>
      </Constrainer>
    </SandboxLayout>
  );
};

export default SandboxButtons;

SandboxButtons.propTypes = {
  location: T.object
};
